import { useCallback, useEffect, useState } from "react"
import Link from "next/link"
import { IBlog } from "@/types"
import { FileText, Search } from "lucide-react"
import ReactTimeAgo from "react-time-ago"
import { useDebounce } from "use-debounce"

import { API } from "@/api"

import { Button } from "@/ui/button"

import { SiteConfig } from "@/config/site"

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command"

export function CommandDialogMenu() {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState<string>("")
  const [searchValue] = useDebounce(search, 1000)
  const [blogs, setBlogs] = useState<IBlog[]>([])
  const getBlogs = useCallback(async () => {
    const { docs } = await API.blogs.posts.getAll({
      published: true,
    })
    setBlogs(docs)
  }, [])
  // load blogs when component mounts
  useEffect(() => {
    getBlogs()
  }, [getBlogs])

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }
    document.addEventListener("keydown", down)
    return () => document.removeEventListener("keydown", down)
  }, [])

  return (
    <div>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput
          placeholder="Type to search content"
          onValueChange={(value) => {
            setSearch(value)
          }}
        />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Suggestions">
            <CommandItem>
              <Link
                href="/blogs"
                title="View all blogs"
                className="flex flex-row gap-x-2 w-full justify-between"
              >
                <div className="flex flex-row gap-x-1">
                  <FileText />
                  <div className="capitalize">Blogs</div>
                </div>
              </Link>
            </CommandItem>
            <CommandItem>
              <Link
                href={SiteConfig.social.medium.url}
                target="_blank"
                title='View all blogs on "Medium"'
                className="flex flex-row gap-x-2 w-full justify-between"
              >
                <div className="flex flex-row gap-x-1">
                  <FileText />
                  <div className="capitalize">Medium Profile</div>
                </div>
              </Link>
            </CommandItem>
          </CommandGroup>
          <CommandGroup heading="Blogs">
            {blogs.map((blog) => {
              return (
                <CommandItem key={blog._id}>
                  <Link
                    href={blog.url}
                    title={`View ${blog.title}`}
                    className="flex flex-row gap-x-2 w-full justify-between"
                    onClick={() => setOpen(false)}
                  >
                    <div className="flex flex-row gap-x-1">
                      <FileText />
                      <div className="capitalize">{blog.title}</div>
                    </div>
                    <div>
                      <ReactTimeAgo
                        date={new Date(blog.createdAt)}
                        locale="en-US"
                      />
                    </div>
                  </Link>
                </CommandItem>
              )
            })}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
      <div>
        <Button
          variant={"ghost"}
          size={"sm"}
          onClick={() => setOpen((open) => !open)}
        >
          <Search />
        </Button>
      </div>
    </div>
  )
}
