interface IConfig {
  baseUrl: string
  title: string
  description: string
  logo: string
  seo: {
    title: string
    description: string
    images: {
      url: string
      alt: string
      width: number
      height: number
    }[]
  }
  authors: {
    url?: string | URL
    name?: string
  }[]
  applicationName: string
  keywords: string[]
  publisher: string
  name: string
  shortName: string
  social: {
    twitter: {
      url: string
      handle: string
    }
    medium: {
      url: string
      handle: string
    }
    instagram: {
      url: string
      handle: string
    }
    linkedin: {
      url: string
      handle: string
    }
  }
  organization: {
    name: string
    shortName: string
    logo: string
    url: string
    description: string
    social: {
      twitter: string
      instagram: string
      linkedin: string
    }
  }
}

const baseUrl = process.env.NEXT_PUBLIC_SITE_URL || "http://localhost:3000"
const logo = `${baseUrl}/icon.png`
const twitterHandle = "@dharmikjagodana"
const instagramHandle = "jagodanadharmik_"
const linkedinHandle = "dharmikjagodana"
const twitterUrl = `https://twitter.com/${twitterHandle}`
const instagramUrl = `https://www.instagram.com/${instagramHandle}`
const linkedinUrl = `https://www.linkedin.com/in/${linkedinHandle}`
const mediumHandle = "@dharmikjagodana"
const mediumUrl = `https://medium.com/${mediumHandle}`
const title = "Dharmik Jagodana"
const seo_title = "Software Developer & Product Innovator"
const seo_description =
  "I'm a skilled software and product developer specializing in tech for startups. Explore my portfolio, read my blogs, subscribe to my newsletter, and connect."

const config: IConfig = {
  title: title,
  logo: logo,
  description: seo_description,
  applicationName: title,
  keywords: [
    "digital marketing",
    "digital marketing tips online",
    "google seo",
    "seo tips",
    "seo search",
    "freelance digital marketing",
    "digital marketing strategies",
    "digital marketing full course",
    "google digital marketing course",
    "digital marketing content",
    "best digital marketing courses",
    "digital marketing earning",
    "digital marketing services",
    "freelance digital marketing course",
    "online marketing blog",
    "article for digital marketing",
    "seo strategy",
    "seo",
    "sem",
    "social media marketing",
    "ppc",
    "content marketing",
    "content of marketing free",
    "free marketing tips",
    "future of digital marketing",
    "best digital marketing website",
    "seo marketing",
    "digital marketing website",
  ],
  seo: {
    title: seo_title,
    description: seo_description,
    images: [
      {
        url: "/og.png",
        alt: seo_title,
        width: 1200,
        height: 630,
      },
    ],
  },
  authors: [
    {
      url: mediumUrl,
      name: "Dharmik Jagodana",
    },
  ],
  baseUrl: baseUrl,
  publisher: title,
  name: title,
  shortName: title,
  social: {
    twitter: {
      url: twitterUrl,
      handle: twitterHandle,
    },
    instagram: {
      url: instagramUrl,
      handle: instagramHandle,
    },
    linkedin: {
      url: linkedinUrl,
      handle: linkedinHandle,
    },
    medium: {
      url: mediumUrl,
      handle: mediumHandle,
    },
  },
  organization: {
    name: title,
    shortName: title,
    logo: logo,
    url: baseUrl,
    description: seo_description,
    social: {
      twitter: twitterUrl,
      instagram: instagramUrl,
      linkedin: linkedinUrl,
    },
  },
}

export const SiteConfig = config
