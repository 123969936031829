import { IForm } from "@/types"

import "@/types/forms/types"

import { ICollectionField } from "@/types/collections/types"

import { get, post } from "./base"

const CMS_SERVER_URL = process.env.CMS_SERVER_URL

export function getFormById(id: string): Promise<IForm> {
  return get(`${CMS_SERVER_URL}/api/v1/forms/${id}`, {}, {})
}
export function getFormFieldsById(id: string): Promise<ICollectionField[]> {
  return get(`${CMS_SERVER_URL}/api/v1/forms/${id}/fields`, {}, {})
}
export function submitFormById(id: string, data: any): Promise<any> {
  return post(`${CMS_SERVER_URL}/api/v1/forms/${id}/submit`, data)
}
