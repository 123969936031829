import axios, { AxiosResponse } from "axios"

const CMS_SERVER_API_KEY = process.env.CMS_SERVER_API_KEY

export function get(
  url: string,
  pagination: {
    $limit?: number
    $page?: number
  },
  filter: {
    [key: string]: any
  }
): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .request({
        url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${CMS_SERVER_API_KEY}`,
        },
        params: {
          ...pagination,
          filter,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response.data)
      })
      .catch((error) => {
        console.error("Error:", error)
        throw new Error(error)
      })
  })
}

export function post(url: string, data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .request({
        url,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${CMS_SERVER_API_KEY}`,
        },
        data,
      })
      .then((response: AxiosResponse) => {
        resolve(response.data)
      })
      .catch((error) => {
        console.error("Error:", error)
        throw new Error(error)
      })
  })
}
