"use client"

import { forwardRef } from "react"
import dynamic from "next/dynamic"
import { MDXEditorMethods, MDXEditorProps } from "@mdxeditor/editor"

import "./class.css"

// This is the only place InitializedMDXEditor is imported directly.
const Editor = dynamic(() => import("./index"), {
  // Make sure we turn SSR off
  ssr: false,
})

// This is what is imported by other components. Pre-initialized with plugins, and ready
// to accept other props, including a ref.
interface EditorProps extends MDXEditorProps {
  markdown: string
  onChange: (md: string) => void
}
export const MarkdownEditor = forwardRef<MDXEditorMethods, EditorProps>(
  (props, ref) => <Editor editorRef={ref} {...props} />
)

// TS complains without the following line
MarkdownEditor.displayName = "MarkdownEditor"
