import React from "react"
import { Mail } from "lucide-react"

import FormComponent from "@/components/forms"
import { Button } from "@/ui/button"
import { Dialog, DialogContent, DialogTrigger } from "@/ui/dialog"

export default function SubscribeForm() {
  const [open, setOpen] = React.useState(false)
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open)
      }}
    >
      <DialogTrigger asChild>
        <Button className="flex gap-x-1">
          <Mail />
          Subscribe
        </Button>
      </DialogTrigger>
      <DialogContent className="h-screen sm:h-auto">
        <FormComponent formId="66cf5b8dc530a5429ca2d9b9/CCp7Uu4yTP7WGsp" />
      </DialogContent>
    </Dialog>
  )
}
