import { clsx, type ClassValue } from "clsx"
import { parseISO } from "date-fns"
import slugify from "slugify"
import { twMerge } from "tailwind-merge"

import { SiteConfig } from "@/config/site"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const formatDate = (dateString: string) => {
  return parseISO(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

export const getFullUrl = (path: string) => {
  if (path.includes("http")) return path
  //remove front slash if it exists
  path = path.replace(/^\//, "")
  return `${SiteConfig.baseUrl}/${path}`
}

export const formatCurrency = (amount: number, currency: string) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  return formatter.format(amount)
}

export const slugifyString = (str: string) => {
  return slugify(str, { lower: true, strict: true, trim: false })
}
