import { SiteConfig } from "./site"

interface ILink {
  title: string
  href: string
  newTab?: boolean
}
export const NavHeaderLinks: ILink[] = [
  {
    title: "Medium",
    newTab: true,
    href: SiteConfig.social.medium.url,
  },
  {
    title: "Blogs",
    newTab: false,
    href: "/blogs",
  },
]
