import { IBlog, IBlogTag } from "@/types"

import { ICollectionField } from "@/types/collections/types"
import { IForm } from "@/types/forms/types"

import {
  getAllBlogs,
  getAllBlogTags,
  getBlog,
  getBlogByPath,
  getBlogBySlug,
  getBlogTagById,
  getBlogTagBySlug,
} from "./blogs"
import { getFormById, getFormFieldsById, submitFormById } from "./form"

const blogs: IBlog[] = []

export const API = {
  forms: {
    async getByFormId(formId: string): Promise<IForm> {
      return getFormById(formId)
    },
    async getFields(formId: string): Promise<ICollectionField[]> {
      return getFormFieldsById(formId)
    },
    async submitForm(formId: string, data: any): Promise<any> {
      return submitFormById(formId, data)
    },
  },
  collections: {
    async get(
      slug: string,
      latest: boolean,
      version?: number
    ): Promise<{ docs: any[] }> {
      return { docs: [] }
    },
  },
  features: {
    async isEnabled(
      featureId: string
    ): Promise<{ enabled: boolean; success: boolean }> {
      return { enabled: true, success: true }
    },
  },
  blogs: {
    posts: {
      async getByPath(path: string): Promise<IBlog> {
        return getBlogByPath(path)
      },
      async getAll(params: { [key: string]: any }): Promise<{ docs: IBlog[] }> {
        const result = await getAllBlogs({
          ...params,
        })
        return result
      },
      async get(params: { slug: string }): Promise<IBlog> {
        return getBlogBySlug(params.slug)
      },
      async getById(params: { id: string }): Promise<IBlog> {
        return getBlog(params.id)
      },
    },
    tags: {
      async getAll(): Promise<{ docs: IBlogTag[] }> {
        return getAllBlogTags()
      },
      async getById(id: string): Promise<IBlogTag> {
        return getBlogTagById(id)
      },
      async getBySlug(slug: string): Promise<IBlogTag> {
        return getBlogTagBySlug(slug)
      },
    },
  },
}
