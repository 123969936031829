import { IForm } from "../forms/types"

export interface ICollectionField {
  slug: string
  title: string
  helpText: string
  type: string
  required: boolean
  defaultValue: string | number | boolean
}

export const ICollectionFieldType = {
  TEXT: "text",
  RICH_TEXT: "rich-text",
  NUMBER: "number",
  BOOLEAN: "bool",
  DATE: "date",
  COLOR: "color",
  LINK: "link",
  EMAIL: "email",
}

export const COLLECTION_FIELD_TYPES: string[] = [
  ICollectionFieldType.TEXT,
  ICollectionFieldType.RICH_TEXT,
  ICollectionFieldType.NUMBER,
  ICollectionFieldType.BOOLEAN,
  ICollectionFieldType.DATE,
  ICollectionFieldType.COLOR,
  ICollectionFieldType.LINK,
  ICollectionFieldType.EMAIL,
]

export interface ICollectionVersion {
  _id: string
  name: string
  version: number
  collectionId: string
  live: boolean
  deprecated: boolean
  creator: string
  fields: ICollectionField[]

  deleted: boolean
  createdAt: Date
  updatedAt: Date
}

export interface ICollectionRecord {
  _id: string
  collectionId: string
  versionId: string
  deleted: boolean
  createdAt: Date
  updatedAt: Date
  archived: boolean
  creator: string
  formId: string | IForm
  data: {
    [key: string]: any
  }
}

export interface ICollection {
  _id: string
  name: string
  slug: string
  collectionId: string
  archived: boolean
  creator: string
  latest: string
  versions: string[]

  deleted: boolean
  createdAt: Date
  updatedAt: Date
}
