import { IBlog, IBlogTag, IPaginationResponse } from "@/types"

import { get } from "./base"

const CMS_SERVER_URL = process.env.CMS_SERVER_URL

export function getAllBlogs(filter: {
  [key: string]: any
}): Promise<IPaginationResponse<IBlog>> {
  return get(
    `${CMS_SERVER_URL}/api/v1/blogs`,
    {
      $limit: 10000,
    },
    filter
  )
}

export function getBlog(id: string): Promise<IBlog> {
  return get(`${CMS_SERVER_URL}/api/v1/blogs/${id}`, {}, {})
}

export function getBlogBySlug(slug: string): Promise<IBlog> {
  return get(`${CMS_SERVER_URL}/api/v1/blogs/path/${slug}`, {}, {})
}

export function getBlogByPath(path: string): Promise<IBlog> {
  return get(`${CMS_SERVER_URL}/api/v1/blogs/path?path=${path}`, {}, {})
}

export function getAllBlogTags(): Promise<IPaginationResponse<IBlogTag>> {
  return get(
    `${CMS_SERVER_URL}/api/v1/blogs/tags`,
    {
      $limit: 10000,
    },
    {}
  )
}

export function getBlogTagById(id: string): Promise<IBlogTag> {
  return get(`${CMS_SERVER_URL}/api/v1/blogs/tags/${id}`, {}, {})
}

export function getBlogTagBySlug(slug: string): Promise<IBlogTag> {
  return get(`${CMS_SERVER_URL}/api/v1/blogs/tags/slug/${slug}`, {}, {})
}
